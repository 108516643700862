<template>
    <div class="skins-deposit">
        <div class="skins-deposit__body">
            <payment-order-info :order="order" v-on="$listeners" />

            <loader v-if="loading" />

            <template v-else>
                <div class="skins-deposit__filters">
                    <div>
                        <h1>
                            <stacks-icon />
                            {{ $t('pay.deposit.title') }}
                        </h1>

                        <filter-actions
                            :select-all="isSelectedAll"
                            :select-all-disabled="0 === skins.items.length"
                            :disabled="skins.loading"
                            @selectAll="selectAllSkins"
                            @reset="resetFilters"
                        />

                        <filter-inputs v-model="filters" :apps="apps" :disabled="skins.loading" />
                    </div>

                    <div>
                        <filter-inputs v-model="filters" :apps="apps" :disabled="skins.loading" />

                        <filter-actions
                            :select-all="isSelectedAll"
                            :select-all-disabled="0 === skins.items.length"
                            :disabled="skins.loading"
                            @selectAll="selectAllSkins"
                            @reset="resetFilters"
                        />
                    </div>
                </div>

                <loader v-if="skins.loading" />

                <div v-else-if="0 === skins.items.length" class="skins-deposit__message">
                    <template v-if="skins.reload">
                        <p>{{ $t('pay.deposit.empty.text') }}</p>

                        <text-button size="small" theme="secondary" @click="fetchSkins">
                            {{ $t('pay.deposit.empty.retry') }}
                            <retry-arrow-icon />
                        </text-button>
                    </template>

                    <p v-else-if="!order.trade_url">{{ $t('pay.deposit.empty.trade_url') }}</p>

                    <p v-else>{{ $t('pay.deposit.empty.default') }}</p>
                </div>

                <skins v-else>
                    <skin
                        v-for="skin in skins.items"
                        :key="skin.assetId"
                        :currency="order.currency"
                        :skin="skin"
                        :is-selected="isSelected(skin)"
                        @click="toggleSkin(skin)"
                    />
                </skins>
            </template>
        </div>

        <pay-cart :loading="cart.loading" :order="order" :skins="cart.items" @close="cart.items = []" @removeSkin="removeSkin" @submit="submitCart" />
    </div>
</template>

<script>
    import { inventory, steamApps, trade } from '@/services/skinsPaymentApi';
    import { CS_GO_ID } from '@/services/skinsPaymentApi/constants/common';
    import FilterActions from '@/components/pay/FilterActions';
    import FilterInputs from '@/components/pay/FilterInputs';
    import Loader from '@/components/Loader';
    import PayCart from '@/components/pay/PayCart';
    import PaymentOrderInfo from '@/components/pay/PaymentOrderInfo';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';
    import Skin from '@/components/pay/Skin';
    import Skins from '@/components/pay/Skins';
    import StacksIcon from '@/components/icons/StacksIcon';
    import TextButton from '@/components/buttons/TextButton';
    import Toast from '@/components/Toast';

    export default {
        name: 'SkinsDeposit',
        components: {
            FilterActions,
            FilterInputs,
            Loader,
            PayCart,
            PaymentOrderInfo,
            RetryArrowIcon,
            Skin,
            Skins,
            StacksIcon,
            TextButton,
        },
        props: {
            order: {
                type: Object,
                required: true,
            },
        },
        computed: {
            isSelectedAll() {
                return !!this.skins.items.length && this.cart.items.length === this.skins.items.length;
            },
        },
        methods: {
            fetchSkins() {
                if (!this.order.trade_url) return;

                this.skins.loading = true;
                this.skins.reload = false;
                this.skins.items = [];
                this.cart.items = [];

                return inventory(this.$route.query.hash, this.filters.appId)
                    .then(({ data }) => (this.skins.items = data.inventory.map(item => ({ assetId: item.assetId, ...item.item }))))
                    .catch(() => {
                        this.skins.reload = true;

                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                text: this.$t('pay.deposit.errors.skins'),
                            },
                        });
                    })
                    .finally(() => (this.skins.loading = false));
            },
            fetchSteamApps() {
                this.loading = true;

                return steamApps()
                    .then(({ data }) => (this.apps = data))
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('pay.deposit.errors.apps'),
                            },
                        })
                    )
                    .finally(() => (this.loading = false));
            },
            isSelected(skin) {
                return this.cart.items.filter(selected => selected.assetId === skin.assetId).length > 0;
            },
            removeSkin(skin) {
                this.cart.items = this.cart.items.filter(item => item.assetId !== skin.assetId);
            },
            resetFilters() {
                this.filters = { appId: this.order.priority_app.id || CS_GO_ID, order: false };

                if (this.isSelectedAll) {
                    this.cart.items = [];
                }
            },
            selectAllSkins(value) {
                this.cart.items = value ? this.skins.items : [];
            },
            toggleSkin(skin) {
                if (!this.cart.loading) {
                    this.cart.items = this.isSelected(skin)
                        ? this.cart.items.filter(selected => selected.assetId !== skin.assetId)
                        : [...this.cart.items, skin];
                }
            },
            submitCart() {
                this.cart.loading = true;

                trade(
                    this.$route.query.hash,
                    this.filters.appId,
                    this.cart.items.map(item => ({ assetid: item.assetId, amount: 1 }))
                )
                    .then(({ data }) => this.$emit('trade', data))
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('pay.cart.error'),
                            },
                        })
                    )
                    .finally(() => (this.cart.loading = false));
            },
        },
        watch: {
            'filters.appId': function () {
                this.fetchSkins();
            },
            'filters.order': function () {
                this.skins.items.sort((a, b) =>
                    this.filters.order
                        ? a.price[this.order.currency] - b.price[this.order.currency]
                        : b.price[this.order.currency] - a.price[this.order.currency]
                );
            },
            'order.trade_url': function () {
                this.fetchSkins();
            },
        },
        data() {
            return {
                apps: null,
                loading: true,
                cart: {
                    items: [],
                    loading: false,
                },
                filters: {
                    appId: this.order.priority_app ? this.order.priority_app.id : CS_GO_ID,
                    order: false,
                },
                skins: {
                    items: [],
                    loading: false,
                    reload: false,
                },
            };
        },
        mounted() {
            this.fetchSteamApps();
            this.fetchSkins();
        },
    };
</script>

<style lang="scss" scoped>
    .skins-deposit {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 0 !important;
        border-radius: 1rem;
        overflow: hidden;
    }

    .skins-deposit__body {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: 60rem;
        padding: 1rem;
        background-color: #10141f;
        overflow: hidden;
    }

    .skins-deposit__content {
        display: flex;
        flex-direction: column;
    }

    .skins-deposit__filters {
        flex-shrink: 0;
        margin: 1rem 0;

        > div {
            padding: 1rem;
            background-color: #121825;

            &:first-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.1rem;
                border-radius: 1rem 1rem 0 0;

                h1 {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 2.2rem;
                        height: 2.2rem;
                        margin-right: 0.5rem;
                        fill: #353e54;
                    }
                }

                .filter-inputs {
                    display: none;
                }
            }

            &:last-child {
                border-radius: 0 0 1rem 1rem;

                .filter-actions {
                    display: none;
                }

                .filter-inputs {
                    display: flex;
                }
            }
        }
    }

    .skins-deposit__message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        button {
            margin-top: 2rem;
        }
    }

    ::v-deep .skins {
        grid-auto-rows: 1fr;
    }

    @media screen and (min-width: 26em) {
        .skins-deposit__filters > div:first-child h1 {
            font-size: 1.5rem;
        }
    }

    @media screen and (min-width: 30em) {
        .skins-deposit__filters > div,
        .skins-deposit__body {
            padding: 1.5rem;
        }
    }

    @media screen and (min-width: 48em) {
        .skins-deposit__filters {
            display: flex;
            align-items: center;

            > div {
                &:first-child {
                    flex: 1 0 auto;
                    margin: 0 0.1rem 0 0;
                    border-radius: 1rem 0 0 1rem;

                    .filter-actions {
                        display: none;
                    }

                    .filter-inputs {
                        display: flex;
                    }
                }

                &:last-child {
                    flex: 0 0 auto;
                    border-radius: 0 1rem 1rem 0;

                    .filter-inputs {
                        display: none;
                    }

                    .filter-actions {
                        display: flex;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 75em) {
        .skins-deposit {
            flex-direction: row;
            height: 100%;
        }

        .skins-deposit__body {
            height: auto;
        }
    }

    @media screen and (min-width: 120em) {
        .skins-deposit__body {
            padding: 3rem;
        }

        .skins-deposit__filters {
            margin: 2.5rem 0;

            > div {
                &:first-child,
                &:last-child {
                    padding: 2rem;
                }

                &:first-child h1 {
                    font-size: 2rem;

                    svg {
                        margin-right: 1rem;
                        width: 2.8rem;
                        height: 2.8rem;
                    }
                }
            }
        }
    }
</style>
