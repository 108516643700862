<template>
    <div :class="{ 'filter-inputs': true, 'filter-inputs_disabled': disabled }">
        <inline-select :options="appOptions" :selected="indexById(this.value.appId)" size="small" @input="selectApp" :show-label="false" />

        <text-button :class="{ 'filter-inputs__price_asc': this.value.order }" size="small" theme="secondary" @click="changeOrder">
            {{ $t('pay.deposit.filters.price') }}
            <arrow-down-icon />
        </text-button>
    </div>
</template>

<script>
    import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
    import InlineSelect from '@/components/inputs/InlineSelect';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'FilterInputs',
        components: {
            ArrowDownIcon,
            InlineSelect,
            TextButton,
        },
        props: {
            apps: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Object,
                required: true,
            },
        },
        computed: {
            appOptions() {
                return this.apps ? this.apps.map(app => ({ label: app.name, image: app.icon })) : [];
            },
        },
        methods: {
            changeOrder() {
                this.$emit('input', { ...this.value, order: !this.value.order });
            },
            indexById(id) {
                return this.apps.findIndex(app => app.id.toString() === id.toString());
            },
            selectApp(index) {
                if (index >= 0) {
                    this.$emit('input', { ...this.value, appId: this.apps[index].id });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter-inputs {
        display: flex;

        > *:not(:last-child) {
            margin-right: 0.5rem;
        }

        &_disabled {
            pointer-events: none;
        }
    }

    .filter-inputs__price_asc {
        svg {
            transform: rotate(180deg);
        }
    }

    @media screen and (min-width: 120em) {
        .filter-inputs {
            > *:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
</style>
